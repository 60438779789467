// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import Platform_Feat_slider from "../components/common/platform-feat-slider"
import light from "../images/textile-engineering/icons/light.svg"
import light_white from "../images/textile-engineering/icons/light_white.svg"
import flexible from "../images/textile-engineering/icons/Flexible.svg"
import flexible_white from "../images/textile-engineering/icons/Flexible_white.svg"
import fit from "../images/textile-engineering/icons/fit.svg"
import fit_white from "../images/textile-engineering/icons/fit_white.svg"
import Construction from "../images/textile-engineering/icons/Construction.svg"
import Construction_white from "../images/textile-engineering/icons/Construction_white.svg"

const TextileEngineering = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),
    grid_1: convertToBgImage(getImage(data.grid_1)),
    grid_2: convertToBgImage(getImage(data.grid_2)),
    grid_3: convertToBgImage(getImage(data.grid_3)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    slider_1: convertToBgImage(getImage(data.slider_1)),
    slider_2: convertToBgImage(getImage(data.slider_2)),
    slider_3: convertToBgImage(getImage(data.slider_3)),
    platform_application_1: convertToBgImage(
      getImage(data.platform_application_1)
    ),
    platform_application_2: convertToBgImage(
      getImage(data.platform_application_2)
    ),
    platform_application_3: convertToBgImage(
      getImage(data.platform_application_3)
    ),
  }

  const [activePlatform, setActivePlatform] = useState(0)

  const platform_feat_slider_array = [
    {
      title: "Light",
      desc: "Creating light form factors using low weight materials to achieve design parameters.",
      icon: light,
      icon_white: light_white,
    },

    {
      title: "Construction",
      desc: "Expert craftsmanship to enable wash, stretch, durability and comfort in creating complex solutions.",
      icon: Construction,
      icon_white: Construction_white,
    },

    {
      title: "Flexibility",
      desc: "Design freedom with form factor optionality ranging from 3D structures to no-seam attachments.",
      icon: flexible,
      icon_white: flexible_white,
    },

    {
      title: "Fit",
      desc: "Achieving customizable fit, from desired sizing for apparel, to engineering our textiles to wrap around hard structures for consumer electronics.",
      icon: fit,
      icon_white: fit_white,
    },
  ]

  const slider = [
    {
      title: "Proprietary ideation and development",
      content:
        "Our unique integrated approach to design and functionality reflects a commitment to sustainability and engineered craftsmanship.",
      image: images.slider_1,
    },
    {
      title: "Integrating technology",
      content:
        "We are pioneers in engineering soft goods with integrated structure and embedded electronic components.",
      image: images.slider_2,
    },
    {
      title: "Industry standards",
      content:
        "We operate ISO 9001, 14001 and 13485, OHSAS 18001 and LEED-compliant manufacturing facilities globally.",
      image: images.slider_3,
    },
    {
      title: "Manufacturing",
      content:
        "Our innovative manufacturing solutions merge craftsmanship with the replicability and scale required for consumer electronics.",
      image: images.slider_1,
    },
  ]

  const [active, setActive] = useState(0)

  const platform = [
    {
      title: "Medical Devices",
      sub_title: "Medical Device Finishes",
      content:
        "Fabric finishes for CPAP devices for more comfort and a bedroom aesthetic, without hindering functionality.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.platform_application_1,
    },
    {
      title: "Smart Accessories",
      sub_title: "Textile Bands and Straps",
      content:
        "Textile straps with functional customizability in areas ranging from knit construction to moisture and bacteria resistance.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.platform_application_2,
    },
    {
      title: "Packaging",
      sub_title: "Paper yarn",
      content:
        "Yarn created from cellulose origins which is recyclable has soft hand-feel, and has great aesthetics. This yarn can be knitted or braided into packaging materials and accessories.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.platform_application_3,
    },
  ]

  return (
    <Layout>
      <Seo title="Textile Engineering" />
      <section>
        <BackgroundImage
          {...images.dusk_texure}
          className="h-screen bg-dusk overflow-hidden"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col w-full z-10 sm:mt-0 -mt-52">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 max-w-2xl text-white">
                    Crafting
                    <div>softer solutions</div>
                  </div>
                  <div className="sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Combining design aesthetic and soft form
                    <div>across a number of applications</div>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className=" absolute right-0 bottom-0">
                  <StaticImage
                    src="../images/textile-engineering/hero.png"
                    alt="Softmatter"
                    placeholder="blurred"
                    width={820}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-mint mb-4 uppercase text-center ">
                    PASSIVE TECHNOLOGIES
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Enabling Smart Wearables
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. The platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug mt-4 text-dusk">
                    Our expertise in textile chemistry, complex knit, injection
                    molding, and more affords us unrestricted design
                    flexibility, whilst ensuring all our products remain
                    washable, breathable, and drapable.
                  </div>
                </div>
              </Fade>
              <div className="mt-24">
                <Fade bottom>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Platform Features
                  </div>
                </Fade>
              </div>
            </div>

            <div className="mt-8">
              <Platform_Feat_slider
                platform_feat_slider_array={platform_feat_slider_array}
                mint
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-dusk">
          <div className=" py-24">
            <Fade bottom cascade>
              <div className="text-white">
                <div className="text-center text-2xl font-firs-thin  mb-4">
                  PLATFORMS
                </div>
                <div className="text-center lg:text-6xl text-4xl mb-4 ">
                  Textile Engineering
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  Enabling technologies provide form to product concept across
                  <div> a number of applications.</div>
                </div>
              </div>
            </Fade>
            <div className="mt-32 lg:mt-20 sm:text-left text-center">
              <div className="flex lg:flex-row flex-col  ">
                <div className="lg:hidden block  w-full    ">
                  <Fade right>
                    <div className=" w-full  ">
                      <BackgroundImage
                        {...images.grid_1}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-32 xl:px-36 flex flex-col justify-center  ">
                  <Fade>
                    <div className="text-white sm:pr-10 pr-0  ">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Engineered Knit
                      </div>
                      <div className=" max-w-xl text-base ">
                        With decades of expertise in Flat, Circular, and Warp
                        knitting techniques and one of the most extensive Flat
                        knitting facilities in Southeast Asia, our ability to
                        create complex structures, designs, and modulus is
                        unparalleled and continuously evolving. We offer our
                        knit expertise in conjunction with our active
                        technologies, combining these competencies as required.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Learn More
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full  lg:block hidden  ">
                  <Fade right>
                    <div className=" w-full ">
                      <BackgroundImage
                        {...images.grid_1}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="lg:w-1/2 w-full   ">
                  <Fade left>
                    <div className=" w-full">
                      <BackgroundImage
                        {...images.grid_2}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-32 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white sm:pr-10 pr-0 ">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Narrow Width Fabric
                      </div>
                      <div className=" max-w-xl  text-base ">
                        In partnership with the world’s largest producer of
                        narrow width fabrics, we offer a collection of
                        elasticated fabrics and elastomeric yarns. Engineered to
                        achieve required stretch and durability, our portfolio
                        of narrow width fabrics supports the drive to make
                        technology invisible. complex design parameters and
                        construction needs.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Learn More
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="lg:hidden block  w-full">
                  <Fade right>
                    <div className=" w-full">
                      <BackgroundImage
                        {...images.grid_3}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-32 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white sm:pr-10 pr-0 ">
                      <div className="text-4xl font-light mb-3 leading-tight xl:mt-14 lg:mt-14">
                        Injection Molding
                      </div>
                      <div className=" max-w-xl  text-base ">
                        Upgrade your product offering with our patented reaction
                        injection molding process that can create 3D contoured
                        performance foam components for use across a variety of
                        applications, from impact protection to the
                        encapsulation of electronics. This process is carried
                        out at lower temperatures, allowing us to utilize
                        thermally sensitive fabrics and integrate electronic
                        components. Additionally, this technology is much
                        greener than traditional molding techniques, generating
                        up to 96% less landfill waste.
                      </div>

                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Learn More
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full  lg:block hidden ">
                  <Fade right>
                    <div className=" w-full">
                      <BackgroundImage
                        {...images.grid_3}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="py-20 bg-chalk ">
          <div className="container mx-auto">
            <Fade top>
              <div className="text-4xl font-light text-center text-dusk mb-4">
                Platform Application
              </div>
            </Fade>
            <Fade bottom>
              <div className="text-2xl font-light text-center mx-auto text-dusk max-w-4xl leading-snug">
                Enabling technologies provide form to product concepts across
                <div>a number of applications.</div>
              </div>
            </Fade>
            <div className="mt-14">
              <div className="flex items-center justify-center lg:gap-10 sm:gap-5 ">
                {platform.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div>
                        <div
                          className={
                            active == index
                              ? "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-5 border-dusk duration-200"
                              : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-5 border-transparent duration-200"
                          }
                          onClick={() => setActive(index)}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col ">
                    <div className="w-full lg:w-2/5">
                      <BackgroundImage
                        {...platform[active].cover}
                        className="bg-chalk py-44"
                      ></BackgroundImage>
                    </div>
                    <div className="w-full lg:w-3/5 bg-dusk p-14 flex flex-col justify-center sm:text-left text-center ">
                      <div className=" text-2xl font-light text-white">
                        {platform[active].sub_title}
                      </div>
                      <div className="text-xl font-light mt-4 text-white max-w-2xl">
                        {platform[active].content}
                      </div>
                      <div className="text-xl font-light text-white max-w-2xl">
                        {platform[active].content2}
                      </div>
                      <div className="text-xl font-light text-white max-w-2xl">
                        {platform[active].content3}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="lg:w-1/2 w-full">
            <BackgroundImage
              {...images.mint_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="lg:w-1/2 w-full ">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 lg:px-0 px-5 text-dusk">
                <div className="text-center text-5xl sm:text-6xl mb-4 px-1">
                  How to Engage
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-mint  hover:bg-mint/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query TextileEngineeringImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_1: file(relativePath: { eq: "textile-engineering/grid_1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_2: file(relativePath: { eq: "textile-engineering/grid_2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_3: file(relativePath: { eq: "textile-engineering/grid_3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    platform_application_1: file(
      relativePath: { eq: "textile-engineering/Medical_device.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    platform_application_2: file(
      relativePath: { eq: "textile-engineering/Smart_Accessories.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    platform_application_3: file(
      relativePath: { eq: "textile-engineering/Paper_Yarn.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_1: file(relativePath: { eq: "home/slider/slider_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_2: file(relativePath: { eq: "home/slider/slider_2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_3: file(relativePath: { eq: "home/slider/slider_3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default TextileEngineering
